<template>
  
  <div class="main left">

    <div class="container">

      <h3 class="h3">The Xmarine Story</h3>

      <p>Thanks for dropping in to take a look at Xmarine. If you haven't been here before, we're a small team of Australian commercial vessel owners and operators working on a brand new website for all of the other commercial mariners in Australia.</p>
      <p>We know that a lot of commercial vessel products, jobs and services can get lost in the sea of recreational boating websites online. That's why we're building a commercial-only platform for people that live and work on the water.</p>

      <div class="spacer s30"></div>

      <h4 class="h4">Our Mission</h4>
      <p>We want to build a long-lasting relationship with our members. We're building this website for you, and one day soon we'll build an awesome app, just for you.</p>
      <p>But to make something awesome for you, we really need to know what you want. That's where you come in...</p>

      <div class="spacer s30"></div>

      <h4 class="h4">We need your ideas!</h4>
      <p>The whole Xmarine project depends heavily on how much information we can get from our new users. We are committed to listening and building what you want to see, whether it is an online marketplace, a social network, a marine jobs board, or a hub of information for commercial mariners.</p>
      <p v-if="!feedbackSent">If you have a few seconds, please tell us which of the features below you'd love to see, or write your own below.</p>
      <p v-if="!feedbackSent" class="highlight">Remember to pop your email address in so we know who to thank!</p>

      <div v-if="!feedbackSent" class="getFeedback">
        <h5 class="h5">Which of these features interest you the most?</h5>

        <div class="choices-wrapper">
          <ul class="choice-cloud">
            <li v-for="(feature, index) in featureCloud" :key="feature.title"><span :class="{selected: feature.selected}" @click="updateFeature(featureCloud, index)">{{feature.title}}</span></li>
          </ul>
        </div>

        <div class="spacer s10"></div>

        <h5 class="h5">Or tell us your own ideas:</h5>
        <div class="form-group-reg">
          <input type="text" class="input" placeholder="Type your idea..." v-model="newFeature">
          <input type="email" class="input" placeholder="Enter your email address" v-model="userEmail">
        </div>

        <div class="buttons">
          <button :disabled="!showButton" class="button is-primary is-medium" @click="shareIdeas()">Send my ideas</button>
        </div>

        <div class="clear"></div>
      </div>
      <div v-else>
        <h5 class="h5"><font-awesome-icon icon="thumbs-up" /> &nbsp;Thanks for your valuable ideas.</h5>
      </div>

      <hr>

      <div class="spacer s30"></div>

      <h4 class="h4">Step 1 - Our new Xmarine marketplace</h4>
      <p>We're building a brand new commercial marine marketplace as our first point of business. Rather than make you wait a year to launch with all the bells and whistles, we're going to quietly launch a beta version towards the end of June, 2021. This will give our early adopters something to play with, and an opportunity for you to share your feedback sooner rather than later.</p>

      <div v-if="!feedbackSent && !notifyEmailDone">
        <h5 class="h5">If you'd like to be involved in the pre-launch, please pop your email address in the box below and we'll let you know when it's ready for testing:)</h5>

        <div class="form-group-reg">
          <input type="email" class="input" placeholder="Enter your email address" v-model="userEmail">
        </div>

        <div class="buttons">
          <button :disabled="!emailIsValid" class="button is-info is-medium" @click="notifyMe()">Tell me when it's ready</button>
        </div>
      </div>
      <div v-else>
        <div class="spacer s10"></div>
        <h5 class="h5"><font-awesome-icon icon="thumbs-up" /> &nbsp;We'll keep you posted..</h5>
      </div>

      <div class="spacer s20"></div>

      <div class="notification">
        <h4 class="centered">Some features of our new marketplace:</h4>
        <ul>
          <li><font-awesome-icon icon="location-arrow" /> It will be 100% FREE for all private listings. Always.</li>
          <li><font-awesome-icon icon="cloud" /> For the next 3 months it will also be free for any commercial business listings (and pretty damn cheap after that!)</li>
          <li><font-awesome-icon icon="users" /> No ads! Instead, you can choose to view paid listings by commercial marine businesses in categories that are relevant to your interests.</li>
          <li><font-awesome-icon icon="exclamation-triangle" /> Tailored to you! The more you can tell us about what you are interested in, and which categories are most relevant to you, the more we can put the right listings in front of you.</li>
          <li><font-awesome-icon icon="comments" /> Easy. Our aim is to make it super easy to list something for sale, and to find what you're looking for.</li>
        </ul>
        <!-- <div @click="showFeedback = true" class="example-comment"><font-awesome-icon icon="comments" /></div> -->

        <div class="clear"></div>

      </div>


      <div class="spacer s100"></div>
      
      <div class="clear"></div>


    </div>

    <page-footer></page-footer>

    
  </div>

</template>


<script>
  
  import { db } from '../../main.js';
  import firebase from 'firebase/app';
  import 'firebase/auth'; 
  import 'firebase/firestore';
  import 'firebase/functions';
  import NProgress from 'nprogress';
  const PageFooter = () => import('../reusables/PageFooter.vue');
  
  export default {

    mounted() {
      firebase.auth().onAuthStateChanged(this.onUserLogin);
    },
    components: {
      PageFooter,
    },
    computed: {
      userData() {
        return this.$store.getters.getUserData;
      },
      selectedFeatures() {
        return this.featureCloud.filter((item) => item.selected).map((item) => item.title);
      },
      showButton() {
        return (this.newFeature != '' || this.selectedFeatures.length != 0) && this.emailIsValid;
      },
      emailIsValid() {
        return (this.userEmail == "") ? false : (this.reg.test(this.userEmail)) ? true : false;
      }

    },
    data() {
      return {
        firebaseid: '',
        newFeature: '',
        featureCloud: [],
        feedbackSent: false,
        userEmail: '',

        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        notifyEmailDone: false,

      }
    },

    methods: {

      onUserLogin: function(user) {
        if (user) {
          this.firebaseid = user.uid;
        }
        this.loadFeatureCloud();
      },

      loadFeatureCloud() {
        let self = this;
        db.collection('helpers').doc('helperFeatures').collection('newFeatures').orderBy('index','asc').onSnapshot((querySnapshot) => {
          self.featureCloud = [];
          querySnapshot.forEach((doc) => {
            self.featureCloud.push({
              selected: false,
              title: doc.data().title,
              slug: doc.data().slug ? doc.data().slug : '',
            });
          });
        });
      },

      updateFeature(list, index) {
        list[index].selected = !list[index].selected;
      },

      shareIdeas() {
        let self = this;
        NProgress.start();
        // var saveInterestedData = firebase.functions().httpsCallable('saveInterestedData');
        // saveInterestedData({
        //   email: self.topNotifyEmail,
        //   searchTerms: self.searchTerms,
        //   numItemsToSell: self.numItems,
        //   buyerOrSeller: self.chosen,
        //   selectedTypes: self.selectedTypes,
        //   selectedCats: self.selectedCats,
        // }); 
        NProgress.done();
        this.feedbackSent = true;
      },

      notifyMe() {
        let self = this;
        NProgress.start();
        var saveInterestedData = firebase.functions().httpsCallable('saveInterestedData');
        // saveInterestedData({
        //   email: self.topNotifyEmail,
        //   searchTerms: '',
        //   numItemsToSell: '',
        //   buyerOrSeller: '',
        //   selectedTypes: [],
        //   selectedCats: [],
        // }); 
        NProgress.done();
        this.notifyEmailDone = true;
      }


    }

  }


</script>


<style lang="scss" scoped>
  
  .notification {
    text-align: left;
    padding: 2rem;
    margin-top: 2rem;
    h4 {
      margin-bottom: 0.6rem;
      color: deepskyblue;
      font-size: 1.3rem;
      font-weight: bold;
    }
    ul {
      li {
        text-indent: -38px;
        margin-left: 30px;
        margin-bottom: 1rem;
        font-size: 1.1rem;
        color: steelblue;
        svg {
          margin-right: 12px;
        }
      }
    }
  }
  .choices-wrapper {
    .choice-cloud {
      margin-top: 1em;
      text-align: left;
      li {
        display: inline-block;
        cursor: pointer;
        margin: 0 12px 1.3rem 0;
        span {
          background: #f3f3f3;
          transition: 0.3s;
          display: block;
          padding: 8px 17px;
          font-size: 1rem;
          border-radius: 30px;
          // &:hover {
          //   color: #fff;
          //   background: #6cc8fb;
          // }
          &.selected {
            background: #8AD1F6;
            color: #fff;
          }
        }
      }
      &.tags {
        li {
          span {
            // &:hover {
            //   background: #69e8c1;
            // }
            &.selected {
              background: #2dc596;
            }
          }
        }
      }
    }
  }



  @media only screen and (max-width: 599px) {

    .choices-wrapper {
      .choice-cloud {
        li {
          span {
            font-size: 0.9rem;
            padding: 7px 10px;
          }
        }
      }
    }

  }

</style>











